<template>
  <div class="login_container">
    <div class="bg"></div>
    <div class="login_box">
      <!-- 头像 -->
      <div class="avatar_box">
        <img src="../../assets/loginn.png" alt />
      </div>
      <!-- 表单 -->
      <el-form :model="loginForm" label-width="0" class="login_form">
        <!-- 用户id-->
        <!--  <el-form-item prop="id">
          <el-input
            v-model="loginForm.id"
            prefix-icon="el-icon-star-off"
            :disabled="edit"
          ></el-input>
        </el-form-item> -->
        <!-- 公司名-->
        <el-form-item prop="commanyname" class="pwd_wrap">
          <el-input :placeholder="$t('modify.company')" v-model="loginForm.commanyname" @keyup.enter.native="isEnter()"
            prefix-icon="el-icon-office-building" :disabled="toEdit == 1 ? false : true"></el-input>
          <i class="el-icon-edit pws_edit" @click="editAdress(1)"></i>
        </el-form-item>
        <!-- 邮箱-->
        <el-form-item prop="email" class="pwd_wrap">
          <el-input :placeholder="$t('modify.email')" v-model="loginForm.email" prefix-icon="el-icon-message"
            ref="secInput" :disabled="toEdit == 2 ? false : true"></el-input>
          <i class="el-icon-edit pws_edit" @click="editAdress(2)"></i>
        </el-form-item>
        <!-- 真实姓名-->
        <!--  <el-form-item prop="username">
          <el-input
            placeholder="请输入真实姓名"
            v-model="loginForm.username"
            prefix-icon="el-icon-user-solid"
            :disabled="edit"
          ></el-input>
        </el-form-item> -->
        <!-- 用户昵称-->
        <el-form-item prop="nickname" class="pwd_wrap">
          <el-input :disabled="toEdit == 4 ? false : true" :placeholder="$t('modify.nickname')"
            v-model="loginForm.nickname" prefix-icon="el-icon-user"></el-input>
          <i class="el-icon-edit pws_edit" @click="editAdress(4)"></i>
        </el-form-item>
        <!-- 密码-->
        <!--  <el-form-item prop="password" class="pwd_wrap">
          <el-input
            placeholder="请输入用户密码"
            v-model="loginForm.password"
            prefix-icon="el-icon-lock"
            type="password"
            :disabled="edit"
          ></el-input>
          <i class="el-icon-edit pws_edit" @click="openEditpwd"></i>
        </el-form-item> -->
        <!-- 再次输入密码-->
        <!-- <el-form-item prop="comfirpsd">
          <el-input
            placeholder="请确认用户密码"
            v-model="loginForm.comfirpsd"
            prefix-icon="el-icon-unlock"
            type="password"
          ></el-input>
        </el-form-item> -->
        <!-- 手机号-->
        <el-form-item prop="phone" class="pwd_wrap">
          <el-input :disabled="toEdit == 3 ? false : edit" :placeholder="$t('modify.phone')" v-model="loginForm.phone"
            prefix-icon="el-icon-phone"></el-input>
          <i class="el-icon-edit pws_edit" @click="modifyPhone"></i>
        </el-form-item>
        <!-- 收货地址-->
        <el-form-item prop="adress" class="pwd_wrap">
          <el-input :disabled="edit" :placeholder="$t('modify.shipping')" v-model="loginForm.adress"
            prefix-icon="el-icon-location-outline"></el-input>
          <i class="el-icon-menu pws_edit" @click="openEditadss"></i>
        </el-form-item>
        <!-- 创建时间-->
        <!-- <el-form-item prop="createDate">
          <el-input
            v-model="loginForm.createDate"
            prefix-icon="el-icon-s-tools"
            :disabled="edit"
          ></el-input>
        </el-form-item> -->
        <!-- 修改时间-->
        <!--       <el-form-item prop="updateDate">
          <el-input
            v-model="loginForm.updateDate"
            prefix-icon="el-icon-setting"
            :disabled="edit"
          ></el-input>
        </el-form-item> -->
        <el-form-item class="btns">
          <el-button type="danger" @click="saveMessge">{{ $t('heard.information') }}</el-button>
          <el-button type="success" @click="pwdChange">{{ $t('modify.password') }}</el-button>
          <el-button type="primary" @click="tocart">{{ $t('heard.Purchased') }}</el-button>
          <el-button type="info" @click="$router.back(-1)">{{ $t('modify.return') }}</el-button>
        </el-form-item>
      </el-form>
      <changePsd ref="changePwd" :curretUser="curretUser"></changePsd>
      <adressview ref="addressBox"></adressview>
      <modifyPhone ref="modifyPhone"></modifyPhone>
    </div>
  </div>
</template>

<script>
import changePsd from "./changePsd.vue";
import adressview from "./adressview.vue";
import axios from '../../api/axios'
import modifyPhone from './modifyPhone';
import { Message } from 'element-ui'

export default {
  components: {
    changePsd,
    adressview,
    modifyPhone
  },
  inject: ["reload"],
  data() {
    return {
      toEdit: false,
      curretUser: '',
      edit: true,
      istoEnter: false,
      loginForm: {
        commanyname: "",
        password: "",
        updateDate: "",
        phone: "",
        nickname: "",
        id: "",
        isNewRecord: "",
        email: "",
        createDate: "",
        username: "",
        adress: ""
      }
    };
  },
  created() {
    this.getUserinfo();
    this.getAdress();
  },
  mounted() {
  },
  methods: {
    editAdress(e) {
      this.toEdit = e;
    },
    saveMessge(e) {
      this.$my_confirm(this.$t('modify.information'), this.$t('login.prompt'), {
        type: "warn",
        cancelBtnText: this.$t('Purchased.Confirm'),
        otherBtnText: "",
        yesBtnText: ""
      })
        .then(action => {
          if (action == "yes") {
            var obj = {
              id: this.loginForm.id,
              commanyname: this.loginForm.commanyname,
              email: this.loginForm.email,
              phone: this.loginForm.phone,
              nickname: this.loginForm.nickname,
            }
            var url = '/reach/portal/api/user/Modify'
            axios.post(url, obj).then(res => {
              console.log(res);
              if (res.data.code === 1) {
                this.$message.success(res.data.message);
                // 修改成功重载页面
                this.reload();
              } else {
                this.$message.error(res.data.message);
              }
            }).catch((err) => {
              this.$message.error(err.message);
            })
          } else if (action == "other") { }
        })
        .catch(() => { });
    },
    pwdChange() {
      this.$refs.changePwd.dialogVisible = true;
    },
    modifyPhone() {
      this.$refs.modifyPhone.dialogVisible = true
    },
    tocart() {
      this.$router.push({ path: "/buyTab" });
    },
    openEditpwd() {
      this.$nextTick(function () {
        this.$refs.changePwd.dialogVisible = true;
      });
    },
    openEditadss() {
      this.$refs.addressBox.dialogVisible = true;
    },
    isEnter() {
      this.istoEnter = true;
    },
    async getUserinfo() {
      var that = this;
      this.curretUser = window.localStorage.getItem('curretUser') || '';
      let res = await this.$Net.get(
        "/f/eshopping/eshoppinglogin/userlist",
        { username: that.curretUser }
      );
      that.$getSame(that.loginForm, res[0]);
      that.loginForm.createDate = that.$timestampToTime(res[0].createDate);
      that.loginForm.updateDate = that.$timestampToTime(res[0].updateDate);
    },
    async getAdress() {
      var that = this;
      var usertoken = window.localStorage.getItem("usertoken") || "";
      let res = await this.$Net.get("/f/eshopping/eshoppingModeAndProduct/addressList", { "eshoppingtoken": usertoken });
      that.loginForm.adress = res[0].province + res[0].city + res[0].district + res[0].address;
    },
  },
};
</script>
<style lang="less" scoped>
.login_container {
  // background-color: #2b4b6b;
  // background-image: linear-gradient(to right , #2b4b6b, skyblue);
  // filter: blur(20px);
  height: 10rem;
  padding-top: 9.5rem;
  position: relative;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #2b4b6b, skyblue);
  // filter: blur(20Px);
}

.login_box {
  width: 10rem;
  // height: 4rem;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%)
}

.avatar_box {
  width: 80Px;
  height: 80Px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 64%;
    height: 69%;
    //   border-radius: 50%;
    background-color: #eee;
  }
}

.login_form {
  /* position: absolute;
    bottom: 5%; */
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  padding-top: 1rem;
}

.btns {
  display: flex;
  justify-content: center;
}

.login_box /deep/ .el-input {
  font-size: 20Px;
}

.login_box /deep/ .el-input__inner {
  height: 0.9rem;
  line-height: 0.4rem;
  width: 98%;
  padding-left: 0.8rem;
}

.login_box /deep/ .el-form-item {
  margin-bottom: 0.35rem;
}

.login_box /deep/ .el-form-item__error {
  left: 1%;
}

.login_box /deep/ .el-input__prefix {
  left: 0.2rem;
}

.login_box /deep/ .el-input__icon {
  font-size: 27Px;
  height: 100%;
  width: 0.6rem;
  line-height: 0.58rem;
}

.login_box /deep/ .el-button {
  width: 2rem;
  // height: 0.6rem;
  font-size: 19Px;
}

.pwd_wrap {
  position: relative;

  .pws_edit {
    position: absolute;
    right: 3%;
    top: 33%;
    font-size: 17Px;
    cursor: pointer;
    display: none;
  }

  &:hover {
    .pws_edit {
      display: block;
    }
  }
}
</style>
