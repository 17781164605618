
const zh = {
    heard: {
        Products: '产品与服务',
        Solution: '解决方案',
        Developer: '开发者中心',
        Intelligent: '智能开发平台',
        buy: 'RIV商城',
        support: '帮助支持',
        Cooperation: '合作与生态',
        About: '关于我们',
        Login: '登录',
        register: "注册",
        information: '修改信息',
        Purchased: "已购商品",
        shopping: "购物车",
        quit: "退出",
        log: "请先登录",
        solve: "为客户解决开发成本高、周期长、难度大等难题，一站式完成IoT产品端到云参考设计，帮助企业实现零基础开发。",
        Learn: "了解更多",
        AIoT: "AIoT硬件解决方案",
        Solutions: "行业解决方案",
        methods: "锐骐物联为您提供多种购买方式",
        online: "通过线上定制或咨询我们的销售。",
        Website: "网站使用",
        Support: "开发者支持",
        consulting: "咨询",
        cooperation: "合作",
        ecology: "生态",
        About: "关于我们",
        Center: '新闻中心',
        contact: "联系我们",
        Partners: "合作伙伴",
        agent: "代理商",
        Space: '众创与孵化器',
        cases: "成功案例",
        Help: "帮助中心",
        Documentation: "开发者文档",
        Business: "商务咨询",
        Download: "文档下载",
        Technical: "技术咨询",
        System: "锐骐全球供应系统",
        keywords: "输入关键词搜索文档",
        Requirements: "锐骐物联强大的全球生态链，帮助您寻找需要的物联网产品，请输入您的需求获取更多信息及支持",
        Mall: "如何购买",
        AirLand: "AirLand端到云一站式服务",
        control: "Airland是一个物联网智能平台， 提供产品端、云端、控制端融合的一站式智能化解决方案。",
        news: "新闻",
        video: "视频",
        Join: "加入我们",
        search: "搜索",
        Start: "现在开始",
        report: "查看报告案例",
        authentication: "实名认证",
        name: "姓名",
        mobile: "手机",
        Address: "住址",
        License: "营业执照",
        remark: "备注",
        Required: "必填项",
        notRequired: "选填项",
        name2: "请输入姓名",
        mobile2: "请输入手机号",
        email: "请输入E-mail",
        License2: "请选择营业执照",
        logged: "您尚未登录",
        application: "申请提交成功",
    },
    home: {
        REACH: "锐骐“天洲AirLand”帮助您快速开启物联网之旅",
        Provide: "为您提供物联网产品端、云端、控制端融合的一站式智能化解决方案",
        Learn: "了解更多",
        success: "成功案例",
        stories: "查看成功案例",
        Industry: "行业动态",
        solution: "一站式智能化解决方案",
        About: "关于锐骐",
        Focusing: "专注物联网领域，拥有成熟的物联网产品，15年以上丰富的研发经验",
        Empowerment: "锐骐助力您的行业实现AIoT赋能",
        Smart: "智慧能源",
        Vehicle: "车联网",
        City: "智慧城市",
        protection: "智慧消防",
        AIoT: "工业物联网"
    },
    login: {
        login: "账户登录",
        logins: "登录",
        username: "用户名",
        password: "密    码 ",
        automatic: "自动登录",
        Forgot: "忘记密码",
        Quick: "快捷登录",
        or: "或",
        free: "免费注册",
        passwords: "请输入您的密码",
        usernames: "请输入用户名",
        Incorrect: "用户名或密码错误",
        failed: "登录失败，请检查格式",
        successful: "用户登录成功",
        phone: "手机号码找回密码",
        Return: "返回登录页",
        email: "此邮箱未注册！",
        email2: "请输入邮箱",
        Retrieve: "邮箱找回密码",
        sure: "确定",
        prompt: "提示",
        empty2: "邮箱不能为空",
        emailFormat: "请输入正确的邮箱格式",
        sent: "验证码已发送",
        exist: "注册手机号不存在",
        confirm: "请确认密码一致",
        changed: "修改密码成功"
    },
    register: {
        number: "请输入手机号",
        registered: "此号码已注册！",
        userName: "用户名已注册！",
        code: "请输入验证码",
        Obtain: "获取验证码",
        seconds: "秒后重新获取",
        enter: "请输入用户名称",
        already: "此用户名称已注册！",
        Check: "检查用户是否注册",
        can: "此用户名称可以注册！",
        // password:"请输入手机号",
        phone: "请输入登录密码",
        reEnter: "请再次输入登录密码",
        company: "请输入您的企业名称（选填）",
        name: "请输入您的称呼（选填）",
        email: "请输入邮箱（选填）",
        agreement: "已阅读，同意",
        agreement2: "保密协议",
        notagreement: "未同意保密协议",
        empty: "电话号码不能为空",
        Submit: "提交",
        numerical: "请输入数字值",
        Incorrect: "电话号码格式不正确",
        passwords: "请输入您的密码",
        passwordss: "请再次输入密码",
        match: "两次输入密码不一致!",
        nickname: "请再次输入账户昵称",
        Chinese: "请重新输入非中文账户昵称",
        // verification:"请输入验证码",
        account: "请输入账号名称",
        successful: "注册成功",
        error: "验证码错误",
    },
    modify: {
        nickname: "请输入昵称",
        company: "请输入公司名",
        email: "请输入邮箱",
        phone: "请输入手机号",
        shipping: "请输入收货地址",
        password: "修改密码",
        return: "返回",
        information: "确认更改信息？",
        initial: "请输入初始密码",
        confirm: "请确认修改密码",
        numerical: "请输入数字值",
        two: "两次输入密码不一致",
        successful: "密码修改成功，即将返回登录界面",
        Initial: "初始密码错误，请重新输入",
        List: "收货地址列表",
        currently: "暂无收货地址,请添加",
        Add: "增 加",
        close: "关 闭",
        payment: "支付",
        shipping: "请输入收货地址",
        sure: "确定删除收货地址？",
        deleted: "删除成功",
        fill: "请先填写收货地址",
        bind: "修改绑定手机号",
        bound: "此号码已绑定其他账号！",
        newPassword: "请输入新密码",
        modification: "修改成功",
        addressee: "收件人",
        Receiving: "收件号码",
        address: "收件地址",
        registration: "微信登陆用户注册",
        informations: "提交信息",
        submission: "确认提交信息?",
        homepage: "提交成功，返回首页"
    },
    Purchased: {
        invoicing: "申请开票",
        editInvoicing: "修改发票",
        downInvoicing: "下载发票",
        invoicingType: "发票类型",
        regularInvoice: "普通发票",
        specialInvoice: "专用发票",
        invoiceTitle: "发票抬头",
        unitTaxNumber: "单位税号",
        registeredAddress: "注册地址",
        registeredPhone: "注册电话",
        bankOfDeposit: "开户银行",
        bankAccount: "银行账号",
        cancel: "取消",
        confirm: "确定",

        selInvtype: "请选择发票类型",
        enterInvTitle: "请输入发票抬头",
        enterUnitTax: "请输入单位税号",
        enterRegAdd: "请输入注册地址",
        enterRegPhone: "请输入注册电话",
        enterBankDeposit: "请输入开户银行",
        enterBankAccount: "请输入银行账号",

        currently: "当前没有",
        OrderData: "订单数据",
        logistics: "填写退货物流",
        tracking: "请输入物流单号",
        company: "请输入物流公司名称",
        and: "请输入物流单号和物流公司名称",
        Submit: "提交物流",
        information: '"提示", "物流信息提交成功"',
        failed: '"提示", "物流信息提交失败"',
        notshipped: "未发货",
        Shipped: "已发货",
        Completed: "已完成",
        refund: "请求退款",
        Exchange: "请求换货",
        Refund: "退款中(未发货)",
        Refunds: "退款中(已发货)",
        progress: "换货中",
        completed: "售后处理完成",
        Unpaid: "未支付订单",
        list: "列表",
        Confirm: "确 定",
        Order: "订单号",
        name: "商品名称",
        image: "商品图片",
        price: "商品价格",
        quantity: "商品数量",
        type: "产品类型",
        original: "商品原价",
        now: "商品现价",
        sales: "累计销量",
        sold: "已售出",
        Stock: "现有库存",
        Shelf: "上架时间",
        time: "购买时间",
        Operation: "订单操作",
        refunded: "申请退款",
        exchanges: "退换货",
        View: "查看物流",
        Delete: "删除订单",
        products: '您确定申请退款吗？',
        goods: '"您确定退换货吗？',
        pay: '"该操作会支付该订单下的所有商品，支付前请仔细查看", "提示"',
        REACH: '锐骐物联',
        operation: "该操作会删除该订单的所有商品，请慎重操作",
        closure: "确认关闭?"
    },
    Cart: {
        name: "新增收货地址",
        Buy: "立即购买",
        Cart: "我的购物车",
        Product: "商品名称",
        quantity: "商品数量",
        pricing: "商品单价",
        Description: "商品描述",
        number: "订单编号",
        inventory: "库存不足",
        review: "审核中",
        Unlisted: "已下架",
        Listing: "上架",
        Module: "模组类型",
        operation: "操作",
        edit: "编辑",
        Remove: "移除",
        Add: "添加商品",
        total: "合计",
        place: "提交订单",
        remove: '请先移除非上架状态商品", "提示"',
        before: '"请先移除库存为0商品，再提交订单", "提示"',
        empty: '"购物车为空，无法提交", "提示"',
        // sure :'"请先移除库存为0商品，再提交订单", "提示"',
        removes: '"确定移除？", "提示"',
        Successfully: '"移除成功", "提示"',
        expired: '登录过期，请先登录',
        completed: '恭喜您，您的支付已经完成！',
        list: '查看购买商品列表',
        address: '请输入您的收货地址',
        region: '请选择区域',
        detailed: '请输入详细收货地址',
        phone: '请输入手机号',
        recipient: '请输入收件人',
        input: '每项输入不能为空，请完成输入',
        Save: '保存地址',
        faileds: '保存失败',
        saved: '保存成功',
        method: '请选择支付方式',
        WeChat: '请扫码微信支付',
        Alipay: '选支付宝支付',
        WeChat2: "微信",
        Alipay2: "支付宝",
        Cancel: "取消",
        ItemStatus: "商品状态"
    },
    agreement: {
        agreement: "锐骐物联网站保密协议",
        Effective: "生效日期：2023年7月19日",
        Updated: "更新日期：2023年7月19日",
        Preface: "前言",
        services: "感谢您使用厦门锐骐物联技术股份有限公司提供的产品和/或服务。",
        confidentiality: "在完成注册程序或以任何方式使用网站服务前，请您务必仔细阅读并透彻理解本网站保密协议(以下或简称“保密协议”)，在确认充分理解后选择接受或不接受本保密协议；一旦您完成“同意条款并注册”，即表明您已阅读并同意受本保密协议的约束",
        confidentiality2: "锐骐承诺对您注册账户时或使用锐骐服务时提交或知悉的信息采取保密措施，不向第三方披露您的信息；",
        confidentiality3: "您承诺对于您在使用锐骐服务时从锐骐获取的任何资料、信息采取保密措施，未经锐骐允许不向第三方披露上述资料和信息。",
        Unless: "除非:",
        According: "1.依据本服务条款或者您与锐骐之间其他服务协议、合同、在线条款等规定可以提供的；",
        According2: "2.依据法律法规的规定或行政、司法等职权部门要求应当提供的；",
        According3: "3.在不违反本服务条款约定责任的前提下，该信息已经公开或能从公开领域获得；"
    },
    buttom: {
        About: "了解我们",
        REACH: "关于锐骐",
        news: "新闻",
        video: "视频",
        Technical: "商务咨询",
        Business: "商务合作",
        consultation: "技术咨询",
        Join: "加入我们",
        Online: "网上商城",
        Mall: "微信商城",
        Offline: "线下购买",
        Services: "产品与服务",
        Solution: "AIoT系统解决方案",
        Partners: "合作伙伴",
        Cooperation: "合作与生态",
        agent: "代理商",
        follow: "欢迎关注我们的公众号，以了解更多信息",
    },
    develop: {
        develop: "系统工程师，硬件开发人员或软件开发人员",
        support: "都可以找到适合的技术支持服务",
        Entering: "进入文档中心",
        Document: "开发者文档中心",
        keywords: "产品开发问题请按照目录查找或输入关键字",
        search: "搜索",
    },
    help: {
        center: "欢迎进入锐骐官网帮助中心",
        keywords: "网站使用问题请按照目录查找或输入关键字",
        consultation: "商务合作咨询",
        address: "地址",
        telephone: "电话",
        facsimile: "传真",
        cooperation: "商务合作咨询",
        WeChat: "微信扫码联系",
        download: "开发者文档下载",
        Select: "选择模组型号，查看对应文档",
        check: "查看",
        Document: "文档名称",
        Update: "更新时间",
        Previous: "上一项",
        Next: "下一项",
        Technical: "技术咨询",
        Log: "现在登录",
        smart: "登录智能开发平台，享受签约专享技术服务",
        Ruiqi: "锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌、OEM厂商和零售连锁的智能化需求，为客户解决开发成本高、周期长、难度大等难题，一站式实现“联网模块+云+电脑/手机控制端” 开发，让零基础的企业也能迅速进入智能领域",
        devices: "所有设备"
    },
    cooperate: {
        Partners: "合作伙伴",
        Registration: "注册签约",
        success: "成功案例",
        address: "代理商签约",
        registration: "代理商列表",
        region: "区域",
        agent: "代理商",
        Contact: "联系方式",
        Station: "工位",
        type: "场地类型",
        register: "预约入驻",
        REACH: "成为锐骐全球合作伙伴，享受专属服务",
        REACH2: "成为锐骐代理商，实现共赢",
        REACH3: "锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌",
        REACH4: "全球开放代理商合作",
        REACH5: "成为锐骐全球合锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌、OEM厂商和零售连锁的智能化需求，为客户解决开发成本高、周期长、难度大作伙伴，享受专属服务",
        REACH6: "锐骐众创与孵化器，全面打造物联网生态",
        REACH7: "锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌、OEM厂商和零售连锁的智能化需求，为客户解决开发成本高、周期长、难度大",
    },
    about: {
        Center: "新闻中心",
        history: "发展历程",
        Offices: "职位描述",
        Description: "分部及办事处",
        email: "简历投递邮箱",
        Video: "视频中心",
        Search: "请输入内容",
        all: "全部",
        full: "全屏",
        information: "新品资讯",
        Quantity: "选购数量",
        Inventory: "库存剩余",
        Add: "加入购物车",
        Product: "产品详情",
        Products: "产品介绍",
        name: "产品名",
        cart: "库存为0，无法加入购物车",
        again: "请重新登录",
        logged: "尚未登录，请先登录",
        Products: "产品介绍",
        Products: "产品介绍",
        Products: "产品介绍",
        Products: "产品介绍",
    },
    other: {
        unknownError: "未知错误",
        formError: "输入的信息有误，请检查",
    }
}
export default zh