<template>
	<div class="gdeil_wrap">
		<div class="gdeil_inner" :class="!scaleShow ? 'gdeilin_mini' : ''">
			<div class="inner_top">
				<div class="gdeil_imgwrap">
					<viewer class="largeimg">
						<img :src="ImgUrl">
					</viewer>
					<div class="smallimg" v-if="false">
						<ul>
							<li @click='getIndex(index, img.url)' v-for="(img, index) in imgUrl" :key="index">
								<img :src="img.url">
							</li>
						</ul>
					</div>
				</div>
				<div class="gdeil_rightwrap">
					<div class="goods_name">{{ tableData[0].productName }}</div>
					<div class="price_wrap">
						<div class="price">{{ $t('Purchased.now') }}：<span>￥{{ tableData[0].productPrice }}</span></div>
						<div class="oldprice">{{ $t('Purchased.original') }}：￥{{ tableData[0].productOldPrice }}</div>
					</div>
					<div class="sellnum">{{ $t('Purchased.sales') }}：<span>{{ tableData[0].productSells }}</span></div>
					<!--<div class="introd">
						<ul>
							<li>hao</li>
							<li>hao</li>
							<li>hao</li>
						</ul>
					</div> -->
					<!-- 	<div class="sortItem" v-for="(item,index) in attrAndValuees" :key="item.attrId">

						<span>{{item.attrName}}:</span>

						<ul>

							<li v-for="(itemm,ind) in item.valuees" :key="itemm.attrId"
								:class="{'cur':sel[index] == ind}" @click="select(index,ind)" class="hand">
								{{itemm.value}}
							</li>

						</ul>

					</div> -->
					<div class="check_num">
						<span>{{ $t('about.Quantity') }}：</span>
						<el-input-number v-model="num" @change="handleChange" :min="1" :max="tableData[0].productSku"
							label="">
						</el-input-number>
						<span class="rem">{{ $t('about.Inventory') }}：{{ tableData[0].productSku }}</span>
					</div>
					<div class="bnt_wrap">
						<el-button type="warning" round @click="addCart(tableData[0])">{{ $t('about.Add') }}</el-button>
						<!-- <el-button type="primary" round @click="nowCart()">立即购买</el-button> -->
					</div>
				</div>
			</div>
			<div class="hx_div">
				<span class="line"></span>
				<span class="text">{{ $t('about.Product') }}</span>
				<span class="line"></span>
			</div>
			<div class="inner_bottom">
				<p>{{ $t('about.Products') }}：<br>{{ tableData[0].productIntroduce }}</p>
				<el-table :data="tableData" border style="width: 100%" :header-cell-style="rowClass">
					<el-table-column prop="productName" :label="$t('about.name')" width="140">
					</el-table-column>
					<el-table-column prop="modeName" :label="$t('Purchased.type')" width="160">
					</el-table-column>
					<el-table-column prop="productSku" :label="$t('Purchased.Stock')" width="120">
					</el-table-column>
					<el-table-column prop="productSells" :label="$t('Purchased.sold')" width="120">
					</el-table-column>
					<el-table-column prop="createDate" :label="$t('Purchased.Shelf')">
					</el-table-column>
					<el-table-column prop="updateDate" :label="$t('help.Update')">
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
import {
	timestampToTime
} from '../../commethods/commethods.js';
import axios from '../../api/axios'
export default {
	data() {
		return {
			scaleShow: true,
			num: 1,
			price: 0,
			imgUrl: [{
				index: 1,
				url: require('../../assets/store/u1053.png')
			},
			{
				index: 2,
				url: require('../../assets/store/u1069.png')
			},
			{
				index: 3,
				url: require('../../assets/store/u1053.png')
			},
			{
				index: 4,
				url: require('../../assets/store/u1069.png')
			},
			{
				index: 5,
				url: require('../../assets/store/u1053.png')
			},
			],
			//大图片默认显示第一张
			ImgUrl: require('../../assets/store/u1053.png'),
			sel: [],
			// sel: [0,0],
			attrAndValuees: [

				{

					'attrId': 1,

					'attrName': '产品型号',

					'valuees': [{

						'attrId': 1,
						'value': 'MTM'

					},
					{

						'attrId': 2,

						'value': 'MTV'

					}
					]
				},

				{

					'attrId': 2,

					'attrName': '发行时间',

					'valuees': [

						{

							'attrId': 4,

							'value': '2015'

						},

						{

							'attrId': 6,

							'value': '2020'

						}

					]

				}
			],
			tableData: [{
				productName: ''
			}],
			cot: 0
		}
	},
	mounted() {
		/*
		商品详情轮播图开启
		var _this = this;
		setInterval(function() {
			_this.cot = ++_this.cot < _this.imgUrl.length ? _this.cot : 0;
			_this.getIndex(_this.cot, _this.imgUrl[_this.cot].url);

		}, 4000);
		商品详情轮播图开启
		*/
		//等待dom渲染完毕再加载
		var that = this;
		that.commonSySize();
		this.$nextTick(() => {
			var p = navigator.platform;
			let mac = p.indexOf("Mac") === 0;
			let x11 = p === "X11" || p.indexOf("Linux") === 0;
			//判断当前系统
			if (p.indexOf("Win") === 0 || mac || x11) {
				that.commonSySize();
				//监听缩放
				window.addEventListener("resize", () => {
					that.commonSySize();
				});
				//监听页面加载
				window.addEventListener("load", () => {
					that.commonSySize();
				});
			} else {
			}
		});
	},
	methods: {
		//公共使用控制页面大小收缩
		commonSySize() {
			let idTag = document.body;
			if (this.$isphone()) { this.scaleShow = false; }
			else {
				if (idTag.offsetWidth <= 1020) {
					this.scaleShow = false;
				} else {
					this.scaleShow = true;
				}
			}
		},
		//点击小图片时将图片路径赋值给大图
		getIndex(index, imgUrl) {
			this.cot = index;
			this.ImgUrl = imgUrl;
		},
		select: function (index, ind) {
			//console.log(this.attrAndValuees[index].valuees[ind].value);
			this.sel[index] = this.sel[index] !== ind ? ind : undefined;
			// 
			//console.log("ha:" + ind);
			//console.log("ha22:" + this.sel[index]);
			/* 
							if (this.sel[index] !== ind) {

								this.$set(this.sel, index, ind)
							} else {
								console.log("等");
								// this.sel[index] = 2;
								this.$set(this.sel, index, undefined)
							} */
			// this.sel = this.sel.concat([]);
		},
		/* rowClass({
			row,
			rowIndex
		}) {
			console.log(row) //表头行标号为0
			console.log(rowIndex) //表头行标号为0
			return 'background:#409eff;color:#ffff'
		} */
		rowClass() {
			return 'background:#409eff;color:#ffff'
		},
		handleChange(value) {
			this.tableData[0].productNumber = value;
		},
		addCart(parm) {
			//console.log(e);

			// localStorage.setItem('addCart', 'sfas');
			// window.localStorage.setItem('addCart', 'sfas')
			/* 	let list = JSON.parse(window.localStorage.getItem('addCart'))||[];
				let addtag=true;
				//list为空自动不执行forEach=》Cannot read property 'forEach' of null"=》非自动
				if(list.length>0){
				list.forEach((v) => {
					if(v.id==id){
						v.buyNum+=this.tableData[0].buyNum;
						addtag=false;
					}
				})
				} 
				
				if(addtag){
					list.push(this.tableData[0]);
				}
				window.localStorage.setItem('addCart', JSON.stringify(list));
				// window.localStorage.setItem('addCart', JSON.stringify(this.tableData));
				this.$router.push({name:'cartView'}) */
			if (this.tableData[0].productSku == 0) {
				this.$my_confirm(this.$t('about.cart'), this.$t('login.prompt'), {
					type: "warn",
					cancelBtnText: this.$t('login.sure'),
					otherBtnText: "",
					yesBtnText: ""
				})
					.then(action => {
						if (action == "yes") { } else if (action == "other") { }
					})
					.catch(() => { });
			}
			else {
				var usertoken = window.localStorage.getItem('usertoken') || '';
				var obj = {
					productId: parm.id, // 产品id
					productName: parm.productName, // 产品名称
					productSells: this.num	// 购买个数
				}
				var url = '/f/eshopping/eshoppingModeAndProduct/saveShoppingCart';
				// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
				axios.post(url, obj, {
					params: {
						eshoppingtoken: usertoken
					}
				}).then(response => {
					if (response.data === 1) {
						this.$router.push({ name: 'cartView' })
					}
					else {
						this.$my_confirm(this.$t('about.again'), this.$t('login.prompt'), {
							type: "warn",
							cancelBtnText: this.$t('login.sure'),
							otherBtnText: "",
							yesBtnText: this.$t('Cart.Cancel')
						})
							.then(action => {
								if (action == "yes") {
									this.$router.push({ path: "/loginn" });
								}
								else if (action == "other") { }
							})
							.catch(() => { });
					}
				})
			}
		},
		nowCart() {
			if (this.tableData[0].productSku == 0) {
				this.$my_confirm(this.$t('about.cart'), this.$t('login.prompt'), {
					type: "warn",
					cancelBtnText: this.$t('login.sure'),
					otherBtnText: "",
					yesBtnText: ""
				})
					.then(action => {
						if (action == "yes") { } else if (action == "other") { }
					})
					.catch(() => { });
			}
			else {
				window.localStorage.setItem('nowCart', JSON.stringify(this.tableData));
				this.$router.push({ name: 'cartView', query: { type: 'nowCart' } })
			}
		}
	},
	created() {	
		//console.log(this.$route.query.id);
		// var proID = this.$route.query.id;
		// var modeId = this.$route.query.modeId;
		var that = this;
		that.$axios({
			method: 'get',
			url: '/f/eshopping/eshoppingModeAndProduct/ProductList',
			// 2024年3月23日，从内存中读取，导致从外部连接跳转进来都是读取已经存在内存中的那个商品，所以改为从url获取
			// params: {
			// 	modeid:  localStorage.getItem("productId"),
			// 	productid:  localStorage.getItem("id"),
			// 	language: localStorage.getItem("locale")
			// }
			// 从url获取modeid和productid
			params: {
				modeid:  this.$route.query.modeId,
				productid:  this.$route.query.id,
				language: localStorage.getItem("locale")
			}
			// url: '/f/eshopping/eshoppingModeAndProduct/ModeList',
		}).then(res => {
			console.log(res)
			that.tableData.splice(0, 1);
			res.data.forEach((v) => {
				let obj = {};
				obj.id = v.id;
				obj.productName = v.productName;
				obj.productIntroduce = v.productIntroduce;
				obj.productPicture = v.productPicture;
				obj.modeName = v.modeName;
				obj.productSku = v.productSku;
				obj.createDate = timestampToTime(v.createDate);
				obj.updateDate = timestampToTime(v.updateDate);
				obj.productSells = v.productSells;
				obj.productPrice = v.productPrice;
				obj.productOldPrice = v.productOldPrice;
				obj.modeId = v.modeId;
				obj.productNumber = 1;
				that.ImgUrl = v.productPicture;
				that.imgUrl[0].url = v.productPicture;
				that.tableData.push(obj);
				console.log(that.tableData)
				/* that.$set(that.sendGoods[i], "value", obj.value);
				that.$set(that.sendGoods[i], "productPicture", obj.productPicture); */
				// that.$set(that.sendGoods[i], obj);不行
				// that.$set(that.sendGoods,that.sendGoods[i],obj);不行
			})
		}).catch(err => {
			this.$notify({
				title: '错误',
				message: err,
				type: 'warning'
			})
			return false
		});

	},
	watch: {
	 $route(from) {
		console.log(from)
	 }
	}
}
</script>
<style>
.check_num .el-input-number {
	/* border-top: 1px solid #edeff1; */
}
</style>
<style scoped lang="less">
.gdeil_wrap {
	width: 100%;
	margin-top: 100px;
	margin: 2rem 0;

	.gdeil_inner {
		width: 71%;
		margin: 0 auto;

		.hx_div {
			width: 100%;
			height: 36px;
			line-height: 36px;
			/* border: 1px solid green; */
			text-align: center;
			margin: 0.5rem 0 0.5rem 0;

			.text {
				font-size: 27Px;
				font-weight: 600;
			}

			.line {
				display: inline-block;
				width: 40%;
				border-top: 0.005208rem solid #cccccc;
				vertical-align: 0.2rem;
				//看到网上有把.text设置为vertical-align:-5px的，试了一下感觉和.header设置的line-height有冲突,效果不太合适。所以将vertical-align设置到.line上了
			}
		}

		.inner_bottom {
			p {
				text-align: left;
				font-size: 18Px;
				margin: 0.5rem 0;
				white-space: pre-wrap;
				line-height: 0.6rem;
			}
		}

		.inner_top {
			display: flex;

			.gdeil_imgwrap {
				flex: 4;

				.largeimg {
					// height: 7.9rem;
					border: 2px solid #57bcf6;
					box-sizing: border-box;
					cursor: pointer;

					img {
						width: 100%;
						height: 100%
					}
				}

				.smallimg {
					width: 100%;

					ul {
						display: flex;
						width: 100%;
						padding: 0;
						margin: 0;
						margin-top: 2px;

						li {
							display: inline-block;
							flex: 1;
							padding: 0px 2px 0 2px;
							border: 1px solid #73c7f6;

							img {
								width: 100%;
								height: 90px;
							}
						}
					}
				}
			}

			.gdeil_rightwrap {
				flex: 6;
				padding-left: 1.2rem;
				font-size: 17Px;

				.goods_name {
					font-size: 30Px;
					font-weight: 600;
					text-align: left;
				}

				.price_wrap {
					padding-left: 0.6rem;
					margin: 0.4rem 0;

					.price {
						font-size: 15Px;
						color: #898b85;
						margin-bottom: 10px;
						text-align: left;

						span {
							font-size: 23Px;
							color: red;
						}
					}

					.oldprice {
						font-size: 15Px;
						color: #898b85;
						text-decoration: line-through;
						text-align: left;
					}
				}

				.sellnum {
					font-size: 20Px;
					color: #898b85;
					margin-bottom: 30px;
					text-align: left;

					span {
						font-size: 18Px;
						color: red;
					}
				}

				.sortItem {
					display: flex;
					margin: 0.5rem 0 0.5rem 0;

					ul {
						margin: 0;
						padding-left: 15px;

						li {
							cursor: pointer;
							padding: 0.17rem;
							display: inline-block;
							border: 0.02rem solid #dedede;
							margin-left: 0.3rem;
						}
					}
				}

				.cur {
					color: #409eff;
					border-color: #409eff !important;
				}
			}
		}

		.check_num {
			text-align: left;

			.rem {
				margin-left: 7px;
				color: #898b85;
			}
		}

		.bnt_wrap {
			width: 6.5rem;
			display: flex;
			justify-content: space-between;
			margin-top: 30px;
		}

		/deep/ .el-input-number__increase {
			font-size: 0.3rem;
		}

		/deep/ .el-input-number {
			width: 3.2rem;
		}

		/deep/ .el-input-number__decrease {
			margin-top: 3px;
			width: 0.55rem;
			height: 0.55rem;
			line-height: 0.55rem;
			border-radius: 0.020833rem 0 0 0.020833rem;
			border: 0.03rem solid #DCDFE6;
		}

		/deep/ .el-input-number__increase {
			margin-top: 3px;
			width: 0.55rem;
			height: 0.55rem;
			line-height: 0.55rem;
			border-radius: 0.020833rem 0 0 0.020833rem;
			border: 0.03rem solid #DCDFE6;
		}

		/deep/ .el-input__inner {
			height: 0.61rem;
		}

		.bnt_wrap {
			margin: 1rem 0 1.5rem 0;

			.el-button {
				width: 2rem;
				// height: 0.6rem;
				border-radius: 0.5rem;
			}
		}

		/deep/ .el-table th {
			padding: 0.21rem 0;
			text-align: center;
		}

		/deep/ .el-table td {
			padding: 0.5rem 0;
			text-align: center;
		}

		/deep/ .el-table .cell {
			line-height: 0.5rem;
		}
	}

	.gdeilin_mini {
		width: 100%;

		.inner_top {
			flex-direction: column;

			.gdeil_rightwrap .price_wrap {
				.price {
					font-size: 27Px;

					span {
						font-size: 32Px;
					}
				}

				.oldprice {
					font-size: 23Px;
				}
			}

			.gdeil_rightwrap .sellnum {
				font-size: 30Px;

				span {
					font-size: 25Px;
				}
			}

			.deil_rightwrap {
				font-size: 30Px;
			}

			.gdeil_imgwrap {
				margin-bottom: 0.7rem;
			}
		}

		.hx_div .text {
			display: block;
			margin-bottom: 0.15rem;
		}

		/deep/ .el-input__inner {
			font-size: 25Px;
		}

		/deep/ .el-button {
			font-size: 25Px;
		}

		/deep/.el-table--border th .cell {
			color: #FFF;
		}

		/deep/.el-table--border td .cell {
			display: inline-block;
			white-space: nowrap;
			word-break: keep-all;
			text-overflow: unset;
		}

		.el-table {
			font-size: 24Px;
		}
	}

}</style>
