<template>
  <div class="topbox_wrap" :class="boxList[0]?'firstBox':''">
    <div class="left_imgwrap">
      <div v-if="boxList[0]" class="firstBoxLeft">
        <img src="../../assets/store/top1.jpg">
        <div class="left_text">
          <p>{{ $t("heard.Products") }}</p>
          <span>{{ $t("heard.solve") }}
					</span>
        </div>
        <div class="left_more">{{ $t("heard.Learn") }}>></div>
      </div>
      <div v-if="boxList[1]">
        <img src="../../assets/store/u984.jpg">
        <div class="sec_lftext_wrap">
          <p @click="toChangeMenu()" :class="!industry?'activeIndus':''">{{ $t("heard.AIoT") }}</p>
          <p @click="toChangeMenu()" :class="industry?'activeIndus':''">{{ $t("heard.Solutions") }}</p>
        </div>
      </div>
      <div v-if="boxList[2]" style="height: 100%;">
        <img src="../../assets/store/u1088.png" class="thirdImg">
      </div>
      <!-- <div v-if="boxList[3]">
        <div class="four_lftext_wrap">
          <span>{{ $t("heard.buy") }}</span>
          <span>{{ $t("heard.methods") }}，<br>{{ $t("heard.online") }}</span>
        </div>
      </div> -->
      <div v-if="boxList[4]">
        <div class="four_lftext_wrap" >
          <span>{{ $t("heard.support") }}</span>
          <span>{{ $t("heard.methods") }}，<br>{{ $t("heard.online") }}</span>
        </div>
      </div>
      <div v-if="boxList[5]" class="six_leftwrap">
        <img src="../../assets/store/u1053.png">
        <p>{{ $t("heard.Cooperation") }}</p>
      </div>
      <div v-if="boxList[6]" class="six_leftwrap">
        <img src="../../assets/store/u1069.png">
        <p>{{ $t("heard.About") }}</p>
      </div>
    </div>
    <div class="right_content">
      <div v-show="boxList[0]" class="right_first" ref="rFirst">
        <div class="content_left">
          <el-row class="cnleft_top" type="flex" justify="space-around">
            <el-col class="cnleft_left"><img src="../../assets/store/u981.png"></el-col>
            <el-col class="cnleft_right">
              <el-row @click.native="toAir" class="cursor">{{ $t("heard.AirLand") }}
              </el-row>
              <el-row>{{ $t("heard.control") }}
              </el-row>
              <el-row>{{menuData[0].menuName}}</el-row>
              <el-row>
                <span>{{menuData[0].menuList[0]!== undefined?menuData[0].menuList[0].menuName:''}}</span>
                <div class="cnleft_type_wrap" v-if="menuData[0].menuList[0] !== undefined">
									<span v-for="(item1,index1) in menuData[0].menuList[0].menuList" :key="index1"
                        @click="toProduce(item1.menuId)" class="cursor">
										{{index1<6?item1.menuName:''}}
									</span>
                </div>
              </el-row>
              <el-row v-if="menuData[0].menuList[1] !== undefined" class="secondRow">{{menuData[0].menuList[1].menuName}}</el-row>
              <el-row v-if="menuData[0].menuList[1] !== undefined">
								<span v-for="(item2,index2) in menuData[0].menuList[1].menuList" :key="index2"
                      @click="toProduce(item2.menuId)" class="cursor">
										{{index2<6?item2.menuName:''}}
								</span>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <div class="content_right">
          <el-row v-if="menuData[0].menuList[2] !== undefined">{{menuData[0].menuList[2].menuName}}</el-row>
          <el-row v-if="menuData[0].menuList[2] !== undefined" type="flex">
            <el-col :span="3" v-for="(item3,index3) in menuData[0].menuList[2].menuList" :key="index3"
                    @click="toProduce(item3.menuId)">{{index3<6?item3.menuName:''}}
            </el-col>
          </el-row>
          <el-row class="fisRightWrap">
            <el-col class="right_botm_col cursor" v-for="(item,index) in fisRightList" :key="index"
                    @click.native="toService(item.menuId)">
              <span>{{item.menuName}}</span>
              <span v-html="item.descript"></span>
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-if="boxList[1]" class="right_sec">
        <div class="sec_rightwrap_one" v-show="!industry">
          <div v-for="(item2,index2) in secfirstRow" :key="index2" style="width:33.33%;">
            <cardBox :solveCardCol="item2"></cardBox>
          </div>
        </div>
        <div class="sec_rightwrap_two" v-show="!industry">
          <div v-for="(item3,index3) in secSeRow" :key="index3" style="width: 34.3%;">
            <cardBox :solveCardCol="item3"></cardBox>
          </div>
        </div>
        <div v-show="industry" class="industryWrap">
            <span v-for="(item4,index4) in industryList" :key="index4" @click="toSolvePath(item4.menuId)">{{item4.menuName}}</span>
        </div>
      </div>
      <!--
      <div v-if="boxList[2]" class="right_third">
        <el-row>
          <el-col :span="9">
            <el-row>
              <el-col :span="6" style="margin-right: 12px;"><img src="../../assets/store/u1092.png">
              </el-col>
              <el-col :span="7" style="position: relative;">
                <div class="rigone_third">
                  <el-row>EveryLink开发平台</el-row>
                  <el-row>在线配置物联网0EM产品，迅速具备<br>联网能力, APP定制及其他增值服务</el-row>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="9" style="margin-left: 78px;">
            <el-row>
              <el-col :span="6"><img src="../../assets/store/u1093.png"></el-col>
              <el-col :span="6" style="position: relative;">
                <div class="rigone_third">
                  <el-row>RIVPie开源平台</el-row>
                  <el-row>开源硬件及软件开发平台</el-row>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
      -->
      <!-- <div v-if="boxList[3]" class="right_four">
        <div v-for="(item4,index4) in fourLeftList" :key="index4" class="four_boxwrap">
          <cardBox :cardCol="item4"></cardBox>
        </div>
      </div> -->
      <div v-if="boxList[4]" class="right_five">
        <div class="four_boxwrap text_top">{{ $t("heard.Website") }}</div>
        <div class="four_boxwrap text_top">{{ $t("heard.Support") }}</div>
        <div class="four_boxwrap text_top">{{ $t("heard.consulting") }}</div>
        <div v-for="(item5,index5) in fiveLeftList" :key="index5" class="four_boxwrap">
          <cardBox :cardCol="item5"></cardBox>
        </div>
      </div>
      <div v-if="boxList[5]" class="right_six">
        <div class="six_textwrap">
          <!-- <div class="six_text">{{ $t("heard.cooperation") }}</div>
          <div class="six_text">{{ $t("heard.ecology") }}</div> -->
        </div>
        <div v-for="(item6,index6) in sixLeftList" :key="index6" class="four_boxwrap">
          <cardBox :cardCol="item6"></cardBox>
        </div>
      </div>
      <div v-if="boxList[6]" class="right_five">
        <div class="four_boxwrap text_top">{{ $t("heard.About") }}</div>
        <div class="four_boxwrap text_top">{{ $t("heard.Center") }}</div>
        <div class="four_boxwrap text_top">{{ $t("heard.contact") }}</div>
        <div v-for="(item7,index7) in sevenLeftList" :key="index7" class="four_boxwrap">
          <cardBox :cardCol="item7"></cardBox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cardBox from './cardBox.vue'
import axios from '../../api/axios'
export default {
  components: {
    cardBox
  },
  name: 'topMenubox',
  // props: ['curindex'],
  data () {
    return {
      industry:false,//解决问题是否点击行业按钮
      industryList:[
                {
                    "menuId":"11115",
                    "menuName":"智慧表计",
                    "descript":null,
                    "icon":null,
                    "menuList":null
                },
                {
                    "menuId":"11116",
                    "menuName":"智慧校园",
                    "descript":null,
                    "icon":null,
                    "menuList":null
                },
                {
                    "menuId":"11117",
                    "menuName":"智慧医疗",
                    "descript":null,
                    "icon":null,
                    "menuList":null
                },
                {
                    "menuId":"11118",
                    "menuName":"智慧校园",
                    "descript":null,
                    "icon":null,
                    "menuList":null
                }
            ],
      menuData: [
        {
          menuId: 'riv',
          menuName: 'IOT模组及调制解调器',
          descript: null,
          icon: null,
          menuList: [
            {
              menuId: 'riv001',
              menuName: 'IOT智能通信模组',
              descript: null,
              icon: null,
              menuList: [
                {
                  menuId: 'riv001001',
                  menuName: 'LoRaWAN',
                  descript: null,
                  icon: null,
                  menuList: []
                },
                {
                  menuId: 'NB-IOT',
                  menuName: 'LoRaWAN',
                  descript: null,
                  icon: null,
                  menuList: []
                },
                {
                  menuId: '4G LTE CAT.1',
                  menuName: 'LoRaWAN',
                  descript: null,
                  icon: null,
                  menuList: []
                },
                {
                  menuId: '4G LTE',
                  menuName: 'LoRaWAN',
                  descript: null,
                  icon: null,
                  menuList: []
                },
                {
                  menuId: '2G',
                  menuName: 'LoRaWAN',
                  descript: null,
                  icon: null,
                  menuList: []
                },
                {
                  menuId: '2G',
                  menuName: 'LoRaWAN33',
                  descript: null,
                  icon: null,
                  menuList: []
                },
                {
                  menuId: '2G',
                  menuName: 'LoRaWAN34',
                  descript: null,
                  icon: null,
                  menuList: []
                },
                {
                  menuId: '2G',
                  menuName: 'LoRaWAN35',
                  descript: null,
                  icon: null,
                  menuList: []
                }
              ]
            },
            {
              menuId: 'riv002',
              menuName: '调制解调器(即插即用)',
              descript: null,
              icon: null,
              menuList: [
                {
                  menuId: 'riv002001',
                  menuName: 'MiniPCIE ',
                  descript: null,
                  icon: null,
                  menuList: []
                },
                {
                  menuId: 'riv002001',
                  menuName: 'USB Dongle',
                  descript: null,
                  icon: null,
                  menuList: []
                }
              ]
            },
            {
              menuId: 'riv003',
              menuName: 'IOT网关',
              descript: null,
              icon: null,
              menuList: [
                {
                  menuId: '网关',
                  menuName: 'MiniPCIE ',
                  descript: null,
                  icon: null,
                  menuList: []
                },
                {
                  menuId: '网关服务',
                  menuName: 'USB Dongle',
                  descript: null,
                  icon: null,
                  menuList: []
                }
              ]
            }
          ]
        }
      ],
      curindexx: 0,
      sendGoods: [],
      boxList: [false, false, false, false, false, false, true],
      fisRightList: [
        {
          menuId: '1111',
          menuName: 'PaaS服务2',
          descript: '一站式完成IOT产品联网模块+云+电脑/手机控制端\r\n基础设施搭建',
          icon: null,
          menuList: null
        },
        {
          menuId: '1113',
          menuName: '数据服务',
          descript: '一站式完成IOT产品联网模块+云+电脑/手机控制端\r\n基础设施搭建',
          icon: null,
          menuList: null
        },
        {
          menuId: '1114',
          menuName: '增值服务服务',
          descript: '一站式完成IOT产品联网模块+云+电脑/手机控制端\r\n基础设施搭建',
          icon: null,
          menuList: null
        },
        {
          menuId: '11112',
          menuName: 'Saas服务3',
          descript: '一站式完成IOT产品联网模块+云+电脑/手机控制端\r\n基础设施搭建',
          icon: null,
          menuList: null
        }
      ],
      secfirstRow: [
        {
        icon: require('../../assets/store/u999.png'),
        menuName: '水表'
      }, {
        icon: require('../../assets/store/u999.png'),
        menuName: '烟感'
      }, {
        icon: require('../../assets/store/u999.png'),
        menuName: 'GPS-Tracker'
      }, {
        icon: require('../../assets/store/u996.png'),
        menuName: '电表'
      }, {
        icon: require('../../assets/store/u996.png'),
        menuName: '气感'
      }, {
        icon: require('../../assets/store/u996.png'),
        menuName: '对讲机'
      }],
      secSeRow: [{
        icon: require('../../assets/store/u997.png'),
        menuName: '气表'
      }, {
        icon: require('../../assets/store/u997.png'),
        menuName: 'DTU/RTU'
      }, {
        icon: require('../../assets/store/u998.png'),
        menuName: '热表'
      }, {
        icon: require('../../assets/store/u998.png'),
        menuName: 'T-box'
      }],
      fourLeftList: [
      /*   {
        imgUrl: require('../../assets/store/kefu.png'),
        name: '线下购买咨询'
      }, {
        imgUrl: require('../../assets/store/u1024.png'),
        name: '微信购买'
      },  */
      {
        imgUrl: require('../../assets/store/u1023.png'),
        name: this.$t('heard.Mall')
      }],
      fiveLeftList: [{
        imgUrl: require('../../assets/store/kefu.png'),
        name: this.$t('heard.Help')
      }, {
        imgUrl: require('../../assets/store/u1024.png'),
        name: this.$t('heard.Documentation')
      }, {
        imgUrl: require('../../assets/store/u1023.png'),
        name: this.$t('heard.Business')
      }, {
        imgUrl: require('../../assets/store/u1024.png'),
        name: this.$t('heard.Download')
      }, {
        imgUrl: require('../../assets/store/u1023.png'),
        name: this.$t('heard.Technical')
      }],
      sixLeftList: [{
        imgUrl: require('../../assets/store/kefu.png'),
        name: this.$t('heard.Partners')
      }, {
        imgUrl: require('../../assets/store/u1024.png'),
        name:this.$t('heard.agent')
      }, 
      // {
      //   imgUrl: require('../../assets/store/u1023.png'),
      //   name: this.$t('heard.Space')
      // },
       {
        imgUrl: require('../../assets/store/u1024.png'),
        name: this.$t('heard.cases')
      }],
      sevenLeftList: []
    }
  },
  mounted () {
    // 等待dom渲染完毕再加载
    this.getProductModuleMenu();
    this.getServiceModuleMenu();
    this.geSolveMenu();
    this.$nextTick(() => {
      var p = navigator.platform
      const mac = p.indexOf('Mac') === 0
      const x11 = p === 'X11' || p.indexOf('Linux') === 0
      // 判断当前系统
      if (p.indexOf('Win') === 0 || mac || x11) {
        // 监听缩放
        window.addEventListener('resize', () => {
          this.commonSySize()
        })
        // 监听页面加载
        window.addEventListener('load', () => {
          this.commonSySize()
        })
      }
    })
  },
  methods: {
    toChangeMenu(){
      this.industry=!this.industry;
    },
    getProductModuleMenu () {
      var url = '/reach/portal/api/menu/getProductMenu'
      axios.post(url,{},{
        params:{
          language:localStorage.getItem('locale')
        }
      }).then(response => {
        console.log(response)
        if (response.data.code === 1) {
          this.menuData = response.data.data;
        }
      })
    },
    getServiceModuleMenu () {
      var url = '/reach/portal/api/menu/getServiceMenu'
      axios.post(url,{},{
        params:{
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code === 1) {
          this.fisRightList = response.data.data
        }
      })
    },
    geSolveMenu () {
      var url = '/reach/portal/api/menu/getSolutionMenu'
      axios.post(url,{},{
        params: {
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code === 1) {
          if(response.data.data[0].menuList.length>6){
            var cot=response.data.data[0].menuList.length-6;
            this.secfirstRow=response.data.data[0].menuList.slice(0,6);
            this.secSeRow=response.data.data[0].menuList.slice(-cot);
          }
          else{
            this.secfirstRow=response.data.data[0].menuList;
            this.secSeRow=[]
          }
          this.industryList=response.data.data[1].menuList;
        }
      })
    },
    commonSySize () {
      if (this.$refs.rFirst) {
        const idTag = document.body
        if (idTag.offsetWidth < 1348) {
          this.$refs.rFirst.style['margin-top'] = '0.1rem'
        } else {
          this.$refs.rFirst.style['margin-top'] = '0.5rem'
        }
      }
    },
    toProduce (routerId) {
      localStorage.setItem('routerId',routerId)
      this.$router.push({ path: '/produceSeries', query: { routerId } })
    },
    toAir () {
      this.$router.push({ path: '/Airlan' })
    },
    toService (serviceTypeId) {
      localStorage.setItem('serviceTypeId',serviceTypeId)
      this.$router.push({ path: '/addValue', query: { serviceTypeId } })
    },
    toSolvePath (solutionTypeId) {
      localStorage.setItem('solutionTypeId',solutionTypeId)
      this.$router.push({ path: '/solve', query: { solutionTypeId } })
    }
  },
  created () {
			 this.sevenLeftList = [{
      imgUrl: require('../../assets/store/kefu.png'),
      name: '帮助中心'
    }, {
      imgUrl: require('../../assets/store/u1024.png'),
      name: '开发者文档'
    }, {
      imgUrl: require('../../assets/store/u1023.png'),
      name: '商务咨询'
    }, {
      imgUrl: require('../../assets/store/u1024.png'),
      name: '文档下载'
    }, {
      imgUrl: require('../../assets/store/u1023.png'),
      name: '技术咨询'
    }]
    this.sevenLeftList[0].name =  this.$t('home.About')
		 	this.sevenLeftList[1].name = this.$t('heard.news')
    this.sevenLeftList[2].name = this.$t('heard.contact')
    this.sevenLeftList[3].name = this.$t('heard.video')
    this.sevenLeftList[4].name = this.$t('heard.Join')
  }
}
</script>
<style lang="less">
	.four_boxwrap {
		width: 33.33%;
	}

	.cnleft_right {
		flex: 3;

		.el-row {
			font-size: 15Px;
			color: #3C3C3C;
			font-family: 'Arial Normal', 'Arial', sans-serif;
			font-weight: 600;
			font-style: normal;
			text-align: left;
      .cursor{
        margin-left: 0.1rem;
        &:hover{
            color: #1db8f3;
          }
      }
		}
		.el-row:nth-child(2) {
			color: #A2A2A2;
			font-family: 'Arial Normal', 'Arial', sans-serif;
			font-weight: 400;
			font-style: normal;
			font-size: 13Px;
			// margin: 0rem 0 0rem 0;
		}

		.el-row:nth-child(3) {
			font-family: 'Arial Black', 'Arial Normal', 'Arial', sans-serif;
			font-weight: 900;
			font-style: normal;
			font-size: 14Px;
			color: #A4A4A4;
		}

		.el-row:nth-child(4) {
			font-size: 13Px;
			letter-spacing: normal;
			color: #3C3C3C;
			font-family: 'Arial Normal', 'Arial', sans-serif;
			font-weight: 400;
			font-style: normal;
			padding-left: 0.5rem;
			margin: 0.4rem 0 0.4rem 0;

			.cnleft_type_wrap {
				display: flex;
				flex-wrap: wrap;
				margin-top:0.25rem;
				span {
					font-size: 12Px;
					color: #333333;
					display: inline-block;
					width: 42%;
					margin-bottom: 0.2rem;
				}
        .cursor{
          &:hover{
            color: #1db8f3;
          }
        }
			}
		}
		.el-row:nth-child(5) {
			font-size: 14Px;
			letter-spacing: normal;
			color: #3C3C3C;
			font-family: 'Arial Normal', 'Arial', sans-serif;
			font-weight: 400;
			font-style: normal;
			padding-left: 0.5rem;
			margin-bottom: 11px;

			.cnleft_botm {
				font-size: 12Px;
				color: #333333;
			}
		}

		.el-row:nth-child(6) {
			margin-right: 0.5rem!important;
			font-size: 14Px;
			letter-spacing: normal;
			color: #3C3C3C;
			font-family: 'Arial Normal', 'Arial', sans-serif;
			font-weight: 400;
			font-style: normal;
			padding-left: 0.5rem;
			margin-top: 0.2rem;

			.el-col {
				font-size: 12Px;
				color: #333333;
			}
		}
	}
	.content_right {
		padding-top: 47px;
		font-size: 14Px;
		letter-spacing: normal;
		color: #3C3C3C;
		font-family: 'Arial Normal', 'Arial', sans-serif;
		font-weight: 400;
		font-style: normal;
        .el-row:nth-child(1) {
            text-align: left;
			padding-left: 0.15rem;
		}
		.el-row:nth-child(2) {
			font-size: 13Px;
			letter-spacing: normal;
			color: #3C3C3C;
			font-family: 'Arial Normal', 'Arial', sans-serif;
			font-weight: 400;
			font-style: normal;
			margin: 0.4rem 0 0.7rem 0;
		}

		.fisRightWrap {
			display: flex;
			flex-wrap: wrap;
      .cursor{
          &:hover{
            span{color: #1db8f3;
              &:nth-child(2){
                color: #1db8f3;
              }
            }
          }
        }
			.right_botm_col {
				display: flex;
				flex-direction: column;
				width: 44.33%;
				margin-bottom: 0.85rem;
				padding-left: 0.17rem;

        &:nth-child(2n){
					padding-left: 0.5rem;
				}
				span {
				    text-align: left;
					&:nth-child(2) {
						margin-top: 10px;
						font-size: 13Px;
						color: #A2A2A2;
						font-family: 'Arial Normal', 'Arial', sans-serif;
						font-weight: 400;
						font-style: normal;
					}
				}
			}
		}
	}
</style>

<style scoped lang="less">
	.topbox_wrap {
		display: flex;
		width: 100%;
		// height: 413px;
		// background-color: #ffffff;

		.left_imgwrap {
			width: 16%;
			position: relative;
			margin-top: -1px;

			img {
				width: 100%;
				height: 100%;
			}

			.thirdImg {
				width: 172%;
			}

			.left_text {
				position: absolute;
				top: 9%;
				left: 12%;
				width: 83%;
				text-align: left;

				p {
					font-size: 16Px;
					color: #FFFCFC;
					font-family: 'Arial Normal', 'Arial', sans-serif;
					font-weight: 600;
					font-style: normal;
					letter-spacing: normal;
					margin-bottom: 0.35rem;
				}

				span {
					color: #FFFFFF;
					line-height: 0.35rem;
					font-size: 14Px;
					font-family: 'Arial Normal', 'Arial', sans-serif;
					font-weight: 400;
					font-style: normal;
				}
			}

			.left_more {
				position: absolute;
				bottom: 16%;
				right: 10%;
				font-size: 17Px;
				color: #FFFCFC;
				font-family: 'Arial Normal', 'Arial', sans-serif;
				font-weight: 400;
				font-style: normal;
				letter-spacing: normal;
				line-height: normal;
			}

			// 第二个子菜单
			.sec_lftext_wrap {
				position: absolute;
				bottom: 20%;
				left: 26%;
        .activeIndus{
           background-color: #e3e3e3;
           border-radius: 2rem;
        }
				p {
					font-size: 0.25rem;
					color: #3C3C3C;
					font-family: 'Arial Normal', 'Arial', sans-serif;
					font-weight: 400;
					font-style: normal;
          padding: 0.1rem 0.2rem;
          cursor: pointer;
          &:hover{
            background-color: #e3e3e3;
            border-radius: 2rem;
          }
					&:nth-child(2) {
						margin-top: 35px;
					}
				}
			}

			.four_lftext_wrap {
				  background-color: #eaeaea;
					height: 308px;
					width: auto;
					padding: 0.4rem 0 0 0.4rem;
					box-sizing: border-box;
					text-align: left;

				span {
					display: block;
					font-size: 18Px;
					color: #333333;

					&:nth-child(2) {
						font-size: 13Px;
						margin-top: 0.3rem;
					}
				}
			}

			.six_leftwrap {
				position: relative;
				height: 100%;

				p {
					font-size: 20Px;
					color: #333333;
					position: absolute;
					top: 3%;
					left: 12%;
				}

				img {
					width: 100%;
				}
			}
		}

		.right_content {
			background-color: #fbf8f8;
			width: 100%;
			padding-left: 53px;

			.right_first {
				display: flex;
				justify-content: space-between;
				margin-top: 0.5rem;

				.content_left {
					// background-color: blue;
					// flex: 3;
					padding-top: 47px;
					// margin-left: 39px;
					width: 30%;

					.cnleft_top {
						width: 100%;
						height: 75px;

						.cnleft_left {
							flex: 1;
							text-align: center;

							img {
						      width: 64Px;
							}
						}
					}
				}

				.content_right {
					// flex: 7;
					width: 58%;
				}
			}

			// 第二个子菜单
			.right_sec {
				width: 100%;
				padding: 3.5% 0 0 9%;

				.sec_rightwrap_one {
					display: flex;
					flex-wrap: wrap;
					width: 74%;

				}

				.sec_rightwrap_two {
					display: flex;
					flex-wrap: wrap;
					width: 72%;

					/* .sec_rightbox {
						width: 34.3%;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-bottom: 35px;

						img {
							width: 46px;
							height: 46px;
							margin-right: 15px;
						}

						span {
							font-size: 14px;
							color: #3C3C3C;
							font-family: 'Arial Normal', 'Arial', sans-serif;
							font-weight: 400;
							font-style: normal;
						}
					} */
				}
        .industryWrap{
          display: flex;
          flex-wrap: wrap;
          width: 79%;
          span{
            width: 25.3%;
            display: block;
            margin-bottom: 1.5rem;
            font-size: 0.25rem;
            cursor: pointer;
          }
        }
			}

			.right_third {
				padding-top: 7%;
				padding-left: 15%;

				.rigone_third {
					position: absolute;
					top: 0;
					left: 0;
					width: 213%;

					.el-row {
						font-size: 17Px;
						color: #3C3C3C;

						&:nth-child(2) {
							margin-top: 11px;
							font-size: 14Px;
							color: #979797;
						}
					}
				}
			}

			.right_four {
				display: flex;
				width: 78%;
				padding: 56px;

				.four_boxwrap {
					width: 33.33%;
				}
			}

			.right_five {
				display: flex;
				width: 86%;
				flex-wrap: wrap;
				padding: 30px;
				justify-content: flex-end;

				.text_top {
					color: #A2A2A2;
					font-size: 14Px;
					margin-bottom: 19px;
				}
			}

			.right_six {
				display: flex;
				width: 77%;
				flex-wrap: wrap;
				padding: 36px 0 0 76px;

				.six_textwrap {
					width: 92%;
					display: flex;

					.six_text {
						width: 50%;
						margin-bottom: 15px;
						font-size: 14Px;
						color: #A2A2A2;

						&:nth-child(2) {
							text-align: center;
						}
					}
				}

			}
		}
	}
  .firstBox{
    height: 7rem;
    .left_imgwrap {
       height: 100%;
       .firstBoxLeft{
         height: 100%;
       }
    }
  }
</style>
