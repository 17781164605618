import { render, staticRenderFns } from "./news.vue?vue&type=template&id=375ad2e8&scoped=true&"
import script from "./news.vue?vue&type=script&lang=js&"
export * from "./news.vue?vue&type=script&lang=js&"
import style0 from "./news.vue?vue&type=style&index=0&id=375ad2e8&scoped=true&lang=css&"
import style1 from "./news.vue?vue&type=style&index=1&id=375ad2e8&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "375ad2e8",
  null
  
)

export default component.exports