<template>
  <div class="login_container">
    <div class="bg"></div>
    <div class="bg_left"></div>
    <div class="login_box">
      <!-- 头像 -->
      <p class="title">注册账户</p>
      <!-- 表单 -->
      <el-form ref="LoginFormRef" :model="loginForm" label-width="0" :rules="LoginFormRules" class="login_form">
        <!-- 手机号-->
        <el-form-item prop="phone">
          <el-select size='mini' v-model="areaNum" autocomplete="off" class="sleTbtype marLeft"
            :popper-append-to-body=false>
            <el-option v-for="item in areaNumList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
          <el-input :placeholder="$t('register.number')" v-model="loginForm.phone" class="marlf"></el-input>
          <span v-if="hadSave">
            <span class="hadTopTitle">已有账户，去<a href="#" @click="tologin">登录</a></span>
            <span class="redFont">{{ $t('register.registered') }}</span>
          </span>
        </el-form-item>
        <el-form-item prop="verification">
          <el-input :placeholder="$t('register.code')" v-model="loginForm.verification"></el-input>
          <el-button class="verifBnt" @click="getverifBnt" v-if="isVerif">{{ $t('register.Obtain') }}</el-button>
          <el-button class="verifBnt verifBnt2" disabled v-else>{{ seconds }}{{ $t('register.seconds') }}</el-button>
        </el-form-item>
        <el-form-item prop="username">
          <el-input :placeholder="$t('register.enter')" v-model="loginForm.username"></el-input>
          <el-button class="verifBnt" @click="isUsernameRegister" v-if="isVerif">{{ $t('register.Check') }}</el-button>
          <span v-if="hadUsername">
            <span class="redFont">{{ $t('register.already') }}</span>
          </span>
          <span v-if="hadUsername === false">
            <span class="redFont">{{ $t('register.can') }}</span>
          </span>
        </el-form-item>
        <!-- 密码-->
        <el-form-item prop="password">
          <el-input :placeholder="$t('register.phone')" v-model="loginForm.password" type="password"
            ref="psd"></el-input>
          <span class="keyboardSpan" @click="openKeyboard(1)" :class="Keybord1 ? 'active' : ''">
            <!-- <i class="far fa-keyboard"></i> -->
            <img src="../../assets/keyboad.png" alt="">
          </span>
        </el-form-item>
        <!-- 再次输入密码-->
        <el-form-item prop="comfirpsd">
          <el-input :placeholder="$t('register.reEnter')" v-model="loginForm.comfirpsd" type="password"
            ref="comfPsd"></el-input>
          <span class="keyboardSpan" @click="openKeyboard(2)" :class="Keybord2 ? 'active' : ''">
            <!-- <i class="far fa-keyboard"></i> -->
            <img src="../../assets/keyboad.png" alt="">
          </span>
        </el-form-item>
        <!-- 公司名-->
        <el-form-item prop="commanyname">
          <el-input :placeholder="$t('register.company')" v-model="loginForm.commanyname"
            @keyup.enter.native="isEnter()"></el-input>
        </el-form-item>
        <!-- 用户昵称-->
        <el-form-item prop="nickname">
          <el-input :placeholder="$t('register.name')" v-model="loginForm.nickname"></el-input>
        </el-form-item>
        <!-- 邮箱-->
        <el-form-item prop="email">
          <el-input :placeholder="$t('register.email')" v-model="loginForm.email" ref="secInput"></el-input>
        </el-form-item>
        <el-form-item class="agreement" prop="agree">
          <el-checkbox v-model="loginForm.agree">{{ $t('register.agreement') }}<a @click="showSerct" target="_blank">{{
        $t('register.agreement2') }}</a></el-checkbox>
        </el-form-item>
        <el-form-item class="btns">
          <el-button class="btns-btn" type="primary" @click="submitRegister">{{ $t('register.Submit') }}</el-button>
          <!-- <el-button type="info" @click="resetLoginForm">重置</el-button> -->
        </el-form-item>
      </el-form>
      <div class="keyWrap" v-if="showKeybord" :class="curEnter == 1 ? 'psdKey' : ''">
        <loginKey :getCurKey.sync="curKey" @delKey="toDel"></loginKey>
      </div>
    </div>
    <serct ref="searchbox" v-if="serboxShow" :sechShow.sync="serboxShow"></serct>
  </div>
</template>

<script>
import { toBase64 } from '../../api/base64.js'
import loginKey from '../../components/loginKey/loginKey.vue'
import serct from '../../components/loginKey/serct.vue'
import axios from '../../api/axios'

export default {
  components: {
    loginKey,
    serct
  },
  data() {
    var checkAgree = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('register.notagreement')))
      } else {
        callback()
      }
    }
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/

      if (!value) {
        return callback(new Error(this.$t('register.empty')))
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,实际输入的数字总是识别成字符串
        // 所以在前面加了一个+实现隐式转换

        if (!Number.isInteger(+value)) {
          callback(new Error(this.$t('register.numerical')))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error(this.$t('register.Incorrect')))
          }
        }
      }, 100)
    }
    var passWord = (rule, value, callback) => {
      if (value == '' && this.$refs.psd != document.activeElement) {
        return callback(new Error(this.$t('register.passwords')))
      } else {
        callback()
      }
    }
    const comfpsd = (rule, value, callback) => {
      if (value === '' && this.$refs.psd != document.activeElement) {
        callback(new Error(this.$t('register.passwordss')))
      } else if (value !== this.loginForm.password && this.$refs.psd != document.activeElement) {
        callback(new Error(this.$t('register.match')))
      } else {
        callback()
      }
    }
    const niname = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('register.nickname')))
      } else if (/^[\u4e00-\u9fa5]+$/.test(value)) {
        callback(new Error(this.$t('register.Chinese')))
      } else {
        callback()
      }
    }
    return {
      serboxShow: false,
      verifyStatus: false,
      curEnter: 0,
      curKey: '',
      showKeybord: false,
      Keybord1: false,
      Keybord2: false,
      hadSave: false,
      hadUsername: '',
      isVerif: true,
      seconds: 60,
      areaNum: '+86',
      areaNumList: ['+86', '+65'],
      istoEnter: false,
      loginForm: {
        agree: false,
        verification: '',
        commanyname: '',
        email: '',
        username: '',
        nickname: '',
        password: '',
        comfirpsd: '',
        phone: ''
      },
      // 正则表达式的相关校验
      LoginFormRules: {
        password: [{ required: true, trigger: 'change', validator: passWord }],
        comfirpsd: [{ required: true, trigger: 'change', validator: comfpsd }],
        phone: [{ required: true, trigger: 'blur', validator: checkPhone }],
        verification: [{ required: true, trigger: 'blur', message: this.$t('register.code') }],
        username: [{ required: true, trigger: 'blur', message: this.$t('register.account') }],
        agree: [{ required: true, trigger: 'blur', validator: checkAgree }]
      }
    }
  },
  computed: {
    commanyname() {
      return this.loginForm.commanyname
    }
  },
  watch: {
    curKey: {
      handler(newName, oldName) {
        if (this.curEnter == 1) {
          this.loginForm.password = this.loginForm.password + newName
        } else {
          this.loginForm.comfirpsd = this.loginForm.comfirpsd + newName
        }
      }
    },
    commanyname: {
      handler(newName, oldName) {
        const reg = new RegExp(/\s/)
        if (
          reg.test(newName) == false &&
          newName.length == 12 &&
          this.istoEnter == true
        ) {
          this.$refs.secInput.focus()
        }
      }
    }
  },
  methods: {
    showSerct() {
      this.serboxShow = !this.serboxShow
    },
    toDel() {
      if (this.curEnter == 1) {
        var psd = this.loginForm.password
        this.loginForm.password = psd.length ? psd.substring(0, psd.length - 1) : psd
      } else {
        var psd = this.loginForm.comfirpsd
        this.loginForm.comfirpsd = psd.length ? psd.substring(0, psd.length - 1) : psd
      }
    },
    async isPhoneRegister() {
      var phoneNumber = this.loginForm.phone
      var url = '/reach/portal/api/user/isPhoneRegister'
      var result = await axios.get(url, {
        params: {
          phone: phoneNumber
        }
      })
      return result
    },
    isUsernameRegister() {
      var username = this.loginForm.username
      var url = '/reach/portal/api/user/isUsernameRegister'
      axios.get(url, {
        params: {
          username: username
        }
      }).then(response => {
        this.hadUsername = response.data
      })
    },
    async getverifBnt() {
      var result = await this.isPhoneRegister()
      if (result.data === true) {
        this.hadSave = true
        return
      } else {
        this.hadSave = false
      }

      // 对手机号进行单个校验
      if (this.loginForm.phone.length == '11') {
        this.isVerif = false
        this.getverifData()
      } else {
        return this.isVerif = true
      }

      var that = this
      var myVar = setInterval(function () {
        --that.seconds
        if (that.seconds == 0) {
          that.isVerif = true
          that.seconds = 60
          clearInterval(myVar)
        }
      }, 1000)
    },
    openKeyboard(e) {
      var that = this
      if (this.curEnter != 0 && this.showKeybord == true) {
        this.showKeybord = false
        this.Keybord1 = false
        this.Keybord2 = false
        if (e != this.curEnter) {
          setTimeout(function () {
            that.showKeybord = true
            that.curEnter = 0
            that.curEnter = e
          }, 500)
        }
      } else {
        this.showKeybord = true
        this.$nextTick(function () {
          that.curEnter = e
          if (e == 1) {
            that.$refs.psd.focus()
            that.Keybord1 = true
          } else {
            that.$refs.comfPsd.focus()
            that.Keybord2 = true
          }
        })
      }
    },
    getverifData() {
      var url = '/reach/portal/api/user/getVerifyCode'
      var phoneNumber = this.loginForm.phone
      axios.post(url, {}, {
        params: {
          phone: phoneNumber
        }
      }).then(response => {
        if (response.data.code === 1) {
          // console.log('验证码已发送')
        }
      })
    },
    checkVerifyCode() {
      var url = '/reach/portal/api/user/checkVerifyCode'
      var checkResult = axios.post(url, {}, {
        params: {
          phone: this.loginForm.phone,
          verifyCode: this.loginForm.verification
        }
      })
      return checkResult
    },
    isEnter() {
      this.istoEnter = true
    },
    resetLoginForm() {
      this.$refs.LoginFormRef.resetFields()
    },
    tologin() {
      this.$router.push({ name: 'loginn', params: { lgin: 'regi' } })
    },
    async submitRegister() {
      var checkResult = await this.checkVerifyCode()
      console.log(checkResult.data)
      if (checkResult.data.code === 0) {
        this.$my_confirm(this.$t('register.error'), "提示", {
          type: "warn",
          cancelBtnText: this.$t('login.sure'),
          otherBtnText: "",
          yesBtnText: ""
        })
        return
      }
      this.$refs.LoginFormRef.validate(async (valid) => {
        if (valid) {
          var psword = this.$md5(this.loginForm.password)
          var obj = {
            phone: this.loginForm.phone,
            verification: this.loginForm.verification,
            username: this.loginForm.username,
            password: psword,
            commanyname: this.loginForm.commanyname,
            nickname: this.loginForm.nickname,
            email: this.loginForm.email,
            agree: false,
            isNewRecord: true
          }
          this.$getSame(obj, this.loginForm)
          obj.password = psword
          var url = '/reach/portal/api/user/register'
          axios.post(url, obj).then(response => {
            if (response.data.code === 1) {
              this.$message.success(this.$t('register.successful'))
              this.$router.push({ path: '/loginn' })
            } else {
              this.$message.warning(response.data.message)
            }
          })
        }
      })
      /*
      this.$refs.LoginFormRef.validate(async (valid) => {
        if (valid) {
          const res = await this.$Net.post(
            '/f/eshopping/eshoppinglogin/saveUser',
            obj
          )
          if (res.status == 200) {
            this.$notify({
              title: '注册成功,即将返回登陆页面',
              type: 'warning'
            })
            var that = this
            setTimeout(function () {
              that.tologin()
            }, 2000)
          }
        } else {
          this.$notify({
            title: '注册失败，请检查格式',
            type: 'warning'
          })
        }
      })
      */
    }
  }
}
</script>
<style lang="less" scoped>
.redFont {
  color: #FF4E00;
  position: absolute;
  right: 8%;
  top: 70%;
}

.hadTopTitle {
  color: #333333;
  font-size: 14Px;
  position: absolute;
  right: 35%;
  top: -93%;
}

.login_container {
  height: 13rem;
  position: relative;

  a {
    text-decoration: underline;
    color: #0E00FF;
  }
}

.bg {
  background: url(../../assets/u3056.png) no-repeat center;
  background-size: cover;
  height: 100%;
}

.bg_left {
  background: url(../../assets/u3058.png) no-repeat;
  background-size: cover;
  height: 30%;
  position: absolute;
  top: 31%;
  left: 13%;
  width: 50%;
}

.login_box {
  width: 7.7rem;
  height: 10.5rem;
  background-color: #fff;
  border-radius: 10Px;
  position: absolute;
  right: -6.5%;
  top: 55%;
  transform: translate(-50%, -50%);
}

.title {
  position: absolute;
  left: 12%;
  top: 2.5%;
  font-size: 23Px;
  font-weight: 600;
}

.login_form {
  position: absolute;
  // bottom: -3%;
  // height: 60%;
  width: 100%;
  padding: 7Px;
  padding-top: 50px;
  box-sizing: border-box;

  .marlf {
    /deep/ .el-input__inner {
      padding-left: 1.27rem;
    }
  }

  .keyboardSpan {
    width: 25Px;
    height: 50Px;
    position: absolute;
    top: -2px;
    right: 12.4%;
    cursor: pointer;
  }

  .active {
    .svg-inline--fa.fa-w-18 {
      color: #4d87b9;
    }
  }

  /deep/ .el-select {
    position: absolute;
    top: 2.7%;
    left: 10%;
    z-index: 100;

    .el-input__inner {
      height: 0.55rem;
      width: 1.2rem;
      padding: 0 8Px;
      background: linear-gradient(180deg, #ffffff 0%, #cccccc 99%);
      box-sizing: border-box;
      border-width: 1Px;
      border-style: solid;
      border-color: #d4d4d4;
      border-radius: 5Px;
      border-top-right-radius: 0Px;
      border-bottom-right-radius: 0Px;
      box-shadow: none;
      color: #3C3C3C;
      font-size: 15Px;
    }

    .el-input__suffix {
      right: -0.05rem;

      .el-select__caret {
        font-weight: 700;
        color: #010101;
        font-size: 0.25rem;
      }
    }

    .el-select-dropdown {
      min-width: auto !important;
      width: 1.3rem !important;

      .el-select-dropdown__item {
        height: 0.4rem;
        line-height: 0.4rem;

        &:hover {
          background-color: #53a8ff;
          color: #fff;
        }
      }
    }

    .el-icon-arrow-up:before {
      color: #828a8d;
      content: "\e790";
      position: relative;
      width: 100%;
      height: 100%;
      top: 11%;
      // left: 50%;
      transform: translate(-50%, -50%);
    }

    .el-icon-arrow-up:after {
      width: 100%;
      height: 100%;
    }
  }

  .verifBnt {
    width: 1.9rem !important;
    height: 0.55rem !important;
    font-size: 13Px !important;
    position: absolute;
    top: 7px;
    right: 9.65%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(219, 219, 219, 1) 100%);
    box-sizing: border-box;
    border-width: 1Px;
    border-style: solid;
    border-color: rgba(212, 212, 212, 1);
    border-radius: 5Px;
    border-top-left-radius: 0Px;
    border-bottom-left-radius: 0Px;
    box-shadow: none;
    color: #848484;
    display: flex;
    justify-content: center;
    align-items: center;

    // text-align: center;
    &:hover {
      border-radius: 5Px;
      background: #53a8ff; //不能用background-color无效
      color: #fff;
    }
  }

  .verifBnt2 {
    width: 2.5rem !important;
  }
}

.keyWrap {
  position: absolute;
  top: 52%;
  right: 0%;
  background-color: #3270a5;
  padding: 10Px 0 0 12Px;
}

.psdKey {
  top: 43%;
}

.agreement {
  /deep/ .el-form-item__content {
    margin-left: 0px;
    text-align: left;
    padding-left: 10%;

    .el-checkbox__inner {
      border: 0.05rem solid #DCDFE6;
      border-radius: 0.03rem;
      width: 0.25rem;
      height: 0.25rem;

      &::after {
        border: 0.04rem solid #FFF;
        border-left: 0;
        border-top: 0;
        height: 0.18rem;
        left: 0.03rem;
        top: -0.09rem;
        width: 0.1rem;
      }

      span {
        font-size: 0.23rem;
      }
    }

    .el-checkbox__label {
      font-size: 0.25rem;
    }
  }
}

.btns {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;

  .btns-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/ .el-form-item__content {
    width: 80%;

    .el-button {
      width: 100%;
      height: 0.6rem;
      border-radius: 6Px;
      font-size: 17Px;
      letter-spacing: 14px;
      background-color: #1fbaf3;
    }
  }
}

.login_box /deep/ .el-input {
  font-size: 20Px;
}

.login_box /deep/ .el-input__inner {
  height: 0.57rem;
  width: 80%;
  padding-left: 0.1rem;
  border-radius: 4Px;
  font-size: 16Px;
}

.login_box /deep/ .el-form-item {
  // margin-bottom: 0.45rem;
}

.el-form-item {
  margin-bottom: 10px;
}

.login_box /deep/ .el-form-item__error {
  left: 10%;
  // line-height: 1.5;
  // font-size: 16Px;
}

.login_box /deep/ .el-input__prefix {
  left: 0.2rem;
}

.login_box /deep/ .el-input__icon {
  font-size: 27Px;
  height: 100%;
  width: 0.6rem;
  line-height: 0.58rem;
}

.login_box /deep/ .el-button {
  width: 1rem;
  height: 0.5rem;
  font-size: 19Px;
}
</style>
